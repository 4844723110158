import { Component, OnInit } from "@angular/core";
import { SendRequestService } from "src/app/services/send-request.service";
import { HttpParams } from "@angular/common/http";
import { AsyncPipe, CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { MatOptionModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-seach-menu",
  templateUrl: "./seach-menu.component.html",
  styleUrls: ["./seach-menu.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class SeachMenuComponent implements OnInit {
  tables: any[] = [];
  myControl = new FormControl();

  options: string[] = ["One", "Two", "Three"];
  filteredOptions: Observable<string[]>;

  constructor(private apiService: SendRequestService) {}

  ngOnInit() {
    this.loadTables();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }
  loadTables() {
    const params = new HttpParams().append("size", "1000");
    const path = `/schema/tables`;
    this.apiService.getRequest(path, params).subscribe((response) => {
      const error = this.apiService.checkError(response);
      if (error) {
        console.log(error);
      } else {
        this.tables = response.records;
      }
    });
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.tables.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }
}
