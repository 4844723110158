import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SendRequestService } from '../services/send-request.service';

export const routingAuthGuard: CanActivateFn = (route, state) => {

  const apiService = inject(SendRequestService);
  const router = inject(Router);

  if (!apiService.authorization) {
    router.navigate(["/login"], {
      queryParams: { go: window.location.pathname },
    });
    return false;
  }

  return true;
};
