<p>seach-menu works!</p>
<div *ngIf="tables">
  <ng-container *ngFor="let table of tables; let i = index">
    <button
      mat-raised-button
      class="m-1"
      [routerLink]="['/tables/', table.name, 'search']">
      {{ table.label }}
    </button>
  </ng-container>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <mat-label>Table</mat-label>
      <input
        type="text"
        placeholder="Table Name ..."
        aria-label="text"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option.label"
          [routerLink]="['/tables/', option.label, 'search']">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
