<div class="av-page-container">

  <div>
    <h1 class="av-page-heading">Cyber Attack Timeline</h1>
  </div>

  <div class="timeline">
    <div class="av-timeline-container  av-side " *ngFor="let timeline of events">


      <h2 class="av-timeline-heading">{{timeline?.date}}</h2>

      <div *ngFor="let attack of timeline.attack; let i = index">

        <div class="av-content "
          [ngClass]="{ 'av-side-left' : attack.flag == 'left',  'av-side-right' : attack.flag == 'right'  }">

          <h2>{{attack.time}}</h2>
          <p>{{attack.content}}</p>
        </div>

      </div>

    </div>




    <!--
        <div class="container-1 left">
          <div class="av-content">
            <h2>2015</h2>
            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
          </div>
        </div>
        <div class="container-1 right">
          <div class="av-content">
            <h2>2012</h2>
            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
          </div>
        </div>
        <div class="container-1 left">
          <div class="av-content">
            <h2>2011</h2>
            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
          </div>
        </div>
        <div class="container-1 right">
          <div class="av-content">
            <h2>2007</h2>
            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
          </div>
        </div> -->
  </div>

</div>