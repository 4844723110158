import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class TimelineComponent {
  events = [
    
    {
     
      date: 'October 12',
      attack:[{

        time:"6:30pm",
        content: " Phishing is an increasingly common cyberthreat",
        flag:"right"

      },
      {

        time:"7:30pm",
        content: " Phishing is an increasingly common cyberthreat",
        flag:"left"

      },
      {

        time:"8:30pm",
        content: " Phishing is an increasingly common cyberthreat",
        flag:"right"

      },
      {

        time:"9:30pm",
        content: " Phishing is an increasingly common cyberthreat",
        flag:"left"
      },
      {

        time:"10:30pm",
        content: " Phishing is an increasingly common cyberthreat",
        flag:"right"

      },
    ]
      
      
    },
    {
     
      date: 'October 14',
      attack:[{

        time:"6:30pm",
        content: 'This is the university I went...',
        flag:"right"
      },
    
      {

        time:"9:30pm",
        content: 'This is the university I went...',
        flag:"left"
      }]
      
      
    },
    
    {
     
      date: 'October 17',
      attack:[
        {

        time:"9:30am",
        content: 'This is the university I went...',
        flag:"right"
      },
      {

        time:"9:30am",
        content: 'This is the university I went...',
        flag:"left"
      },
    ]
      
      
    }
  ];
}
