<app-navigation *ngIf="hasRoute()" (onToggleSidebar)="opened = !opened"></app-navigation>
<!-- <div [ngClass]=" {'display-row':sideBarHandler()   }"> -->
<div [ngClass]=" {'display-row':drawerVisible   }">

  <!-- <app-drawer *ngIf="sideBarHandler()"></app-drawer> -->
  <ng-container *ngIf="drawerVisible">

    <app-drawer></app-drawer>
  </ng-container>


  <!-- <div (click)="bodyClicked($event)" [ngClass]=" {'overflow-handle':sideBarHandler()   }"> -->
  <div (click)="bodyClicked($event)" [ngClass]=" {'overflow-handle':drawerVisible}">
    <router-outlet></router-outlet>
  </div>
</div>